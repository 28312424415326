<template>
  <app-modal-payout-detail :show="showModalPayoutDetail" @close="showModalPayoutDetail = false" :payout="selectPayout"
    @update:payout="selectPayout = $event" processedPaymentLinkRoute="balance.transaction.payment-link" />
  <app-modal-payment-link-detail :show="showModalPaymentLinkDetail" @close="showModalPaymentLinkDetail = false"
    :payment_link="selectPaymentLink" @update:payment_link="selectPaymentLink = $event" />
  <div>
    <div class="px-4 border-b pt-10 pb-4">
      <div>
        <p class="font-medium">{{ $t("wallet.overview.title") }}</p>
      </div>
    </div>
  </div>
  <div class="grid grid-rows-1 md:grid-cols-4 gap-3 my-10 text-gray-600">
    <button class="
        shadow
        p-7
        bg-white
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      " :class="[
        {
          'ring ring-primary': isPaymentLink,
        },
      ]" @click="fetchListPaymentLink()">
      <p class="text-md font-medium">
        {{ $t("wallet.overview.on_the_way_swipe") }}
      </p>
      <paragraph-shimmer :is-loading="balanceOverviewLoading" :lines="1" :random-size="true" />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
            $formats.currency(
              balanceOverview.currency,
              balanceOverview.total_payment_paid
            )
        }}
      </p>
    </button>
    <button class="
        shadow
        p-7
        bg-white
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      " :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.REQUESTED,
        },
      ]" @click="fetchListPayout(PAYOUT_STATUS.REQUESTED)">
      <p class="text-md font-medium">
        {{ $t("wallet.overview.expected_payout") }}
      </p>
      <paragraph-shimmer :is-loading="balanceOverviewLoading" :lines="1" :random-size="true" />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
            $formats.currency(
              balanceOverview.currency,
              balanceOverview.total_payout_requested
            )
        }}
      </p>
    </button>
    <button class="
        shadow
        p-7
        bg-white
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      " :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.PROCESSING,
        },
      ]" @click="fetchListPayout(PAYOUT_STATUS.PROCESSING)">
      <p class="text-md font-medium">
        {{ $t("wallet.overview.otw_bank") }}
      </p>
      <paragraph-shimmer :is-loading="balanceOverviewLoading" :lines="1" :random-size="true" />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
            $formats.currency(
              balanceOverview.currency,
              balanceOverview.total_payout_processing
            )
        }}
      </p>
    </button>
    <button class="
        shadow
        p-7
        bg-white
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      " :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.APPROVED,
        },
      ]" @click="fetchListPayout(PAYOUT_STATUS.APPROVED)">
      <p class="text-md font-medium">
        {{ $t("wallet.overview.on_the_way_bank") }}
      </p>
      <paragraph-shimmer :is-loading="balanceOverviewLoading" :lines="1" :random-size="true" />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
            $formats.currency(
              balanceOverview.currency,
              balanceOverview.total_payout_approved
            )
        }}
      </p>
    </button>
  </div>
  <app-table :loading="isPaymentLink ? paymentLinkLoading : payoutLoading"
    :apiResponse="isPaymentLink ? apiPaginationPaymentLink.data : apiPaginationPayout?.data ?? []"
    @params-changed="paramsChanged" :showEmptyButton="false" :filters="isPaymentLink ? filterPaymentLink : filterPayout"
    @on-item-click="onItemClick">
    <template v-if="isPaymentLink" v-slot:header>
      <th v-if="isMasterBusinessRole" class="font-bold">{{ $t("wallet.transaction.business_name") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.amount") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.charges") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.net_amount") }}</th>
      <th class="font-bold">&nbsp;</th>
      <th class="font-bold">{{ $t("wallet.overview.header.title") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.email") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.date") }}</th>
    </template>

    <template v-else v-slot:header>
      <th v-if="isMasterBusinessRole" class="font-bold">{{ $t("wallet.transaction.business_name") }}</th>
      <th class="font-bold">
        {{ $t("wallet.payout.bank_name") }}
      </th>
      <th class="font-bold">
        {{ $t("wallet.payout.account_no") }}
      </th>
      <th class="font-bold">
        {{ $t("wallet.payout.date") }}
      </th>
      <th class="font-bold">
        {{ $t("wallet.payout.amount") }}
      </th>
      <th class="font-bold">
        {{ $t("wallet.payout.status") }}
      </th>
    </template>

    <template v-if="isPaymentLink" v-slot:body="slotData">
      <td v-if="isMasterBusinessRole">
        {{ slotData.model.business.name }}
      </td>
      <td>
        <p class="font-medium">
          {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
          }}
        </p>
      </td>
      <td>
        <p class="font-medium">
          {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).charges
              )
          }}
        </p>
      </td>
      <td>
        <p class="font-medium">
          {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).net_amount
              )
          }}
        </p>
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <app-badge :status="'success'" class="flex space-x-1 text-xs">
          <p>
            {{ $t("wallet.overview.succeded") }}
          </p>
        </app-badge>
      </td>
      <td>
        {{ slotData.model.title }}
      </td>
      <td>
        {{ slotData.model.email }}
      </td>
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
    </template>

    <template v-else v-slot:body="data">
      <td v-if="isMasterBusinessRole">
        {{ data.model.business.name }}
      </td>
      <td>
        {{ data.model.business.bank?.bank.display_name }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no }}
      </td>
      <td>
        {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td>
        <app-badge class="capitalize w-24" :status="getStatus(data.model?.status)">
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>
  </app-table>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";
import BUSINESS_STATUS from "@/utils/const/business_status";
import { ParagraphShimmer } from "vue3-shimmer";

export default {
  components: {
    ParagraphShimmer,
  },
  data() {
    return {
      filters: {},
      showModalPayoutDetail: false,
      selectPayout: null,
      showModalPaymentLinkDetail: false,
      selectPaymentLink: null,
      loadFilterFromUrl: false,
      isPaymentLink: true,
      payoutStatus: null,
      isPayoutRequested: false,
      PAYOUT_STATUS: PAYOUT_STATUS,
      filterPaymentLink: [],
      filterPayout: []
    };
  },
  computed: {
    apiPaginationPayout() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationPaymentLink() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },

    payoutLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    paymentLinkLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },

    balanceOverviewLoading() {
      return this.$store.getters["walletStore/balanceOverviewLoading"];
    },

    balanceOverview() {
      return this.$store.getters["walletStore/balanceOverview"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business_for_select"];
    },
  },

  beforeMount() {
    if (
      !this.$lodash.isEmpty(this.$route.query) &&
      this.$route.query["filter[has_status]"] != null
    ) {
      this.loadFilterFromUrl = true;
      this.filters = {
        "filter[has_status]": this.$route.query["filter[has_status]"],
      };
    }

    this.$store.dispatch("paymentStore/retrievePaymentLinks", {
      "filter[has_status]": "paid",
    });
  },

  async mounted() {
    this.$store.dispatch("walletStore/retrieveBalanceOverview", this.isMasterBusinessRole);

    await this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });

    this.filterPaymentLink = [
      {
        key: 'filter[title]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.title'),
        value: '',
      },
      {
        key: 'filter[email]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.email'),
        value: '',
      },
      {
        key: 'filter[currency]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.currency'),
        value: '',
      },
      {
        key: 'filter[amount]',
        type: 'number',
        placeholder: this.$t('payment.link.filter.amount'),
        value: '',
      },
      {
        key: 'filter[payment_link_id]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.payment_link_id'),
        value: '',
      },
      {
        key: 'filter[attempt_id]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.payment_attempt_id'),
        value: '',
      },
      {
        key: 'filter[settlement_id]',
        type: 'text',
        placeholder: this.$t('payment.link.filter.settlement_id'),
        value: '',
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: this.$t('payment.link.filter.date_created_at'),
        value: '',
      },
    ];

    this.filterPayout = [
      {
        key: 'filter[_id]',
        type: 'text',
        placeholder: this.$t('wallet.payout.id'),
        value: '',
      },
      {
        key: 'filter[amount]',
        type: 'text',
        placeholder: this.$t('wallet.payout.amount'),
        value: '',
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: this.$t('wallet.payout.date'),
        value: '',
      }
    ];

    if (this.isMasterBusinessRole) {
      this.filterPaymentLink.unshift({
        key: 'filter[business_id]',
        type: 'select',
        placeholder: this.$t('payout.table.account.header_business_name'),
        items: this.all_business,
      });
      this.filterPayout.unshift({
        key: 'filter[business_id]',
        type: 'select',
        placeholder: this.$t('payout.table.account.header_business_name'),
        items: this.all_business,
      });
    }

  },

  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
          return "light";
        case PAYOUT_STATUS.REQUESTED:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
      }
    },

    paramsChanged(filters, queryParams) {
      this.filters = filters;
      if (this.filters["filter[business_id]"] != null) {
        this.filters["filter[business_id]"] = filters["filter[business_id]"]?.id;
      }
      this.filterFormatDate(filters["filter[created_at_between]"]);
      if (this.isPaymentLink) {
        this.$store.dispatch("paymentStore/retrievePaymentLinks", {
          ...this.filters,
          "filter[has_status]": "paid",
        });
      } else {
        this.$store.dispatch("walletStore/retrieveWalletPayouts", {
          ...this.filters,
          "filter[status]": this.payoutStatus,
        });
      }
    },

    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },

    fetchListPayout(status) {
      this.isPaymentLink = false;
      this.isPayoutRequested = true;
      this.payoutStatus = status;
      this.$store.dispatch("walletStore/retrieveWalletPayouts", {
        "filter[status]": status,
      });
    },

    fetchListPaymentLink() {
      this.isPaymentLink = true;
      this.isPayoutRequested = false;
      this.payoutStatus = null;
      this.$store.dispatch("paymentStore/retrievePaymentLinks", {
        "filter[has_status]": "paid",
      });
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    onItemClick(data) {
      if (this.isPaymentLink) {
        this.showModalPaymentLinkDetail = !this.showModalPaymentLinkDetail;
        this.selectPaymentLink = data;
      } else {
        this.showModalPayoutDetail = !this.showModalPayoutDetail;
        this.selectPayout = data;
      }
    },

    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    getPaymentAmount(payments) {
      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null
      };

      payments.forEach((payment) => {
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges += (payment.amount - payment.settlement_amount) * -1
      });

      return paymentAmount;
    },
  },
};
</script>
